/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateCustomer Component
 * Description: This component allows users to create a new customer, providing details such as name, email, phone, address, and an optional profile image.
 *
 * Props:
 * - onClose: () => void - Callback function to close the modal or form.
 * - onSave: (data: any) => void - Callback function triggered when the customer data is saved.
 *
 */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ICustomer } from 'interfaces/customer.interface';
import { Col, Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import { CustomRow, FormContent } from '@components/team/create/createTeamMember/CreateTeamMember';
import {
    CameraIcon,
    FooterButton,
} from '@components/team/create/createServiceProvider/CreateServiceProvider';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';
import ModelHeader from '@components/common/modelHeader/ModelHeader';
import { palette } from 'styled/common';

//create customer
const CreateCustomer = ({ onClose, onSave }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required(t('This field is required')),
        lastname: Yup.string().required(t('This field is required')),
        // email: Yup.string().email().required(t('This field is required')),
        phone: Yup.string()
            .min(11, t('Phone must be a valid phone'))
            .required(t('This field is required')),
    });

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });

    const { errors } = formState;

    useEffect(() => {
        setTimeout(() => {
            setValue('firstname', '');
            setValue('lastname', '');
            setValue('email', '');
            setValue('phone', '');
            setValue('address', '');
            setValue('address2', '');
            setValue('city', '');
            setValue('zipcode', '');
            setValue('password', '');
        });
    }, [setValue]);

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;

            setValue(name as never, phone as never);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: ICustomer) => {
        setLoading(true);
        api.post('/customers', { ...data, email: data.email.toLowerCase(), photo: profileImage })
            .then((res: any) => {
                onSave(res.data._id);
                setLoading(false);
                openSnackbar(t('Customer added successfully'));
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar(e?.response?.data?.message);
            });
    };

    const handleCapture = ({ target }: any) => {
        new Compressor(target.files[0], {
            quality: 0.6,
            success: res => {
                const reader: any = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result);
                    }
                };
            },
        });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <CustomRow>
                    <Col lg={6}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <ModelHeader
                                title={t('Add New Customer')}
                                path={'/customers'}
                                onClose={() => !loading && onClose()}
                            />
                            <Card>
                                <FormContent>
                                    <Circle>
                                        <CircleLabel
                                            htmlFor="faceImage"
                                            aria-label="upload picture">
                                            {profileImage ? (
                                                <NameCircle src={profileImage}></NameCircle>
                                            ) : (
                                                <CameraIcon className="fa fa-camera" />
                                            )}
                                        </CircleLabel>
                                        <FileInput
                                            accept="image/jpeg/png"
                                            id="faceImage"
                                            type="file"
                                            onChange={handleCapture}
                                        />
                                    </Circle>
                                    <InputField>
                                        <Input
                                            label={t('Email')}
                                            name="email"
                                            value={getValues('email')?.toLowerCase()}
                                            onChange={onChange}
                                            error={errors.email as any}
                                        />
                                    </InputField>
                                    <CityField display={'flex'}>
                                        <Input
                                            label={t('First Name')}
                                            name="firstname"
                                            value={getValues('firstname')}
                                            onChange={onChange}
                                            error={errors.firstname as any}
                                        />
                                        <Input
                                            label={t('Last Name')}
                                            name="lastname"
                                            value={getValues('lastname')}
                                            onChange={onChange}
                                            error={errors.lastname as any}
                                        />
                                    </CityField>
                                    <InputField>
                                        <Input
                                            allowPhoneNumberOnly={true}
                                            maxLength={14}
                                            label={t('Phone')}
                                            name="phone"
                                            value={getValues('phone')}
                                            onChange={onChange}
                                            error={errors.phone as any}
                                        />
                                    </InputField>
                                    <div>
                                        <InputField>
                                            <Input
                                                label={t('Street address & number')}
                                                name="address"
                                                value={getValues('address')}
                                                onChange={onChange}
                                            />
                                        </InputField>
                                        <InputField>
                                            <Input
                                                label={t('Suite number (optional)')}
                                                name="address2"
                                                value={getValues('address2')}
                                                onChange={onChange}
                                            />
                                        </InputField>
                                        <CityField>
                                            <City>
                                                <Input
                                                    label={t('City')}
                                                    name="city"
                                                    value={getValues('city')}
                                                    onChange={onChange}
                                                />
                                            </City>
                                            <Input
                                                label={t('Postal Code')}
                                                name="zipcode"
                                                value={getValues('zipcode')}
                                                onChange={onChange}
                                            />
                                        </CityField>
                                    </div>
                                    <FooterButton>
                                        <Button
                                            bgtype={'secondary'}
                                            type={'submit'}
                                            label={t('Add Customer')}></Button>
                                    </FooterButton>
                                </FormContent>
                            </Card>
                        </Form>
                    </Col>
                </CustomRow>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    width: 100%;
    padding: 1.71rem;
`;

const Form = styled.form``;
const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    background-clip: border-box;
    border: 0;
    /* margin-top: 1.5rem; */
`;

const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;

const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;

const NameCircle = styled.img`
    background: #e5e7eb;
    color: #7e8299;
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 100%;
    cursor: pointer;
`;

const InputField = styled.div`
    margin-bottom: 1.71rem;
`;

const CityField = styled.div<any>`
    margin-bottom: 1.71rem;
    display: ${({ display }) => (display ? display : 'grid')};
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem /* 16px */;
`;

const City = styled.div`
    grid-column: span 2 / span 2;
`;

const FileInput = styled.input`
    display: none;
`;

export default CreateCustomer;

/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: BlockModel
 * Description: This component represents a modal for blocking time slots for a service provider. Users can specify the date,
 * start time, and end time to block availability during that period. Block appears as appointment. The component integrates with various UI elements such as
 * input fields, selects, and buttons to facilitate the blocking process. Developed with React and styled-components for styling.
 *
 * Props:
 * - onClose: (is_added?: boolean) => void - Callback function triggered when the block model is closed, optionally indicating whether a block was added.
 * - serviceProviderId: any - The ID of the service provider associated with the block.
 * - blockDate: any - The date for which the block is being created.
 * - startTime: any - The start time of the block.
 * - endTime: any - The end time of the block.
 * - time_select?: '12hours' | '24hours' - A selection for the time format, indicating whether the time is in 12-hour or 24-hour format.
 *
 */

import Button from '@components/Button';
import { Input, LoaderOverlay, Select, useSnackbar } from '@components/common';
import { FooterButton } from '@components/team/create/createServiceProvider/CreateServiceProvider';
import { api } from 'helpers/auth-axios';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';

export interface IBlockModel {
    onClose(is_added?: boolean): void;
    serviceProviderId: any;
    blockDate: any;
    startTime: any;
    endTime: any;
    time_select?: any;
}

const formatDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm:ss');
};

const BlockModel = ({
    onClose,
    serviceProviderId,
    blockDate,
    startTime,
    endTime,
    time_select,
}: IBlockModel) => {
    const { t }: any = useTranslation();
    const [startDate, setStartDate] = useState<any>();
    const [start, setStart] = useState<any>(formatDate(startTime));
    const [end, setEnd] = useState<any>(formatDate(endTime));
    const [loading, setLoading] = useState(false);
    const [openSnackbar] = useSnackbar();

    const closeModal = (event: any) => {
        if (event.target === event.currentTarget) {
            onClose(false);
        }
    };

    const appointmentTime: { label: string; value: any }[] = [];
    for (let i: any = 0; i < 24; i++) {
        for (let j = 0; j < 12; j++) {
            appointmentTime.push({
                label: moment({
                    hour: i,
                    minute: j * 5,
                }).format(time_select === '12' ? 'hh:mm a' : 'HH:mm'),
                value: moment({ hour: i, minute: j * 15 }).format('HH:mm'),
            });
        }
    }

    const blockStartDate =
        moment(startDate ? startDate : blockDate).format('YYYY-MM-DD') +
        'T' +
        moment(start).format('HH:mm') +
        ':00';

    const blockEndDate =
        moment(startDate ? startDate : blockDate).format('YYYY-MM-DD') +
        'T' +
        moment(end).format('HH:mm') +
        ':00';

    const getServiceProvider = () => {
        const block = {
            booked_from_iso: moment(blockStartDate).format(),
            booked_till_iso: moment(blockEndDate).format(),
            is_blocked: true,
        };
        setLoading(true);
        api.post(`/appointments/blocked/${serviceProviderId}`, block)
            .then((res: any) => {
                setLoading(false);
                openSnackbar(t('Block added successfully!'));
                onClose(true);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    const response = e?.response?.data?.response;
                    openSnackbar(response?.message);
                }
                onClose(false);
            });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <Container>
                <Model tabIndex={-1} onClick={e => !loading && closeModal(e)}>
                    <ModelBody lg={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '1rem 0',
                            }}>
                            <ModelTitle>
                                <ModelHeading> {t('Block Details')}</ModelHeading>
                            </ModelTitle>
                            <ModelClose>
                                <CloseButton onClick={() => !loading && onClose(false)}>
                                    {' '}
                                    <i className="fal fa-times"></i>
                                </CloseButton>
                            </ModelClose>
                        </div>

                        <CustomDiv>
                            <InputGroup>
                                <CalenderDate>
                                    <Input
                                        type="date"
                                        label={t('Date')}
                                        min={new Date().toISOString().split('T')[0]}
                                        value={
                                            startDate
                                                ? moment(startDate).format('YYYY-MM-DD')
                                                : moment(blockDate).format('YYYY-MM-DD')
                                        }
                                        onChange={(e: any) => setStartDate(e.value)}
                                    />

                                    <input
                                        name="hidenDate"
                                        type="date"
                                        min={moment().format('YYYY-MM-DD')}
                                        value={
                                            startDate
                                                ? moment(startDate).format('YYYY-MM-DD')
                                                : moment(blockDate).format('YYYY-MM-DD')
                                        }
                                        onChange={(e: any) => setStartDate(e.target.value)}
                                    />
                                </CalenderDate>
                            </InputGroup>
                            <InputGroup>
                                <Select
                                    label={t('Start')}
                                    disabled={true}
                                    value={moment(start).format(
                                        time_select === '12' ? 'hh:mm a' : 'HH:mm'
                                    )}
                                    options={appointmentTime}
                                    onChange={(val: string) => {
                                        const newStart =
                                            moment(start).format('YYYY-MM-DD') + 'T' + val + ':00';

                                        setStart(newStart);
                                    }}
                                />
                            </InputGroup>
                            <InputGroup>
                                <Select
                                    label={t('End')}
                                    disabled={true}
                                    value={moment(end).format(
                                        time_select === '12' ? 'hh:mm a' : 'HH:mm'
                                    )}
                                    options={appointmentTime}
                                    onChange={(val: string) => {
                                        const newStart =
                                            moment(end).format('YYYY-MM-DD') + 'T' + val + ':00';

                                        setEnd(newStart);
                                    }}
                                />
                            </InputGroup>
                        </CustomDiv>
                        <FooterButton>
                            <Button
                                ifClicked={() => getServiceProvider()}
                                bgtype={'secondary'}
                                label={t('Add Block')}></Button>
                        </FooterButton>
                    </ModelBody>
                </Model>
            </Container>
        </>
    );
};

const Model = styled(Row)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 1.7rem;
    padding-top: 0;
    max-width: 35vw;
    ${media.xs`
        width: 100%;
        max-width: 100%;
    `}
    ${media.sm`
        width: 100%;
        max-width: 60vw;
    `}
     ${media.md`
        width: 100%;
        max-width: 45vw;
    `}
    ${media.lg`
        max-width: 35vw;
    `}
`;

const ModelClose = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`;

const CloseButton = styled.div`
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    font-size: 1.7rem;
    border: none;
    cursor: pointer;
`;

const ModelTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
`;

const CustomDiv = styled.div``;

const InputGroup = styled.div`
    margin-bottom: 1rem;
`;

const ModelHeading = styled.h1`
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    ${media.xs`
     font-size: 1.7rem;
    `}
`;

const CalenderDate = styled.div`
    position: relative;
    input[name='hidenDate'] {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: absolute;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        top: 0;
        bottom: 0;
    }
    input[name='hidenDate']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;

export default BlockModel;

/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Login Component
 * Description: This component handles user login, allowing users to log in with their email and password.
 * It supports both team and admin login, and includes form validation, error handling, and business selection.
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import { Input, Select, useSnackbar } from '@components/common';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { userActions } from 'actions/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useRouter from 'hooks/router';
import SideCover from '@components/common/sideCover/SideCover';
import { businessActions } from 'actions/business';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import BusinessSelectionModal from '@components/common/models/SelectBusinessModel';

const Login = () => {
    useEffect(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('url');
        localStorage.clear();
    }, []);

    const { t }: any = useTranslation();
    const router = useRouter();
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar();
    const dispatch = useAppDispatch();
    const [authMethod, setAuthMethod] = useState('email');
    const [isTeamLogin, setIsTeamLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [roleList, setRole] = useState([
        { value: 'serviceProvider', label: t('Service Provider') },
        { value: 'others', label: t('Others') },
        { value: 'teamMember', label: t('Team Member') },
        { value: 'customer', label: t('Customer') },
    ]);
    const [selectedRole, setSeletedRole] = useState('');
    const [openBusinessModel, setOpendBusinessModel] = useState(false);
    const [userBusinesses, setAllUserBusiness] = useState({ business: [], selected: '' });

    useEffect(() => {
        if (router.query.success !== undefined) {
            if (router.query.provider) setAuthMethod(router.query.provider as string);
            if (router.query.accessToken) {
                dispatch(userActions.getCurrentUser(router.query.accessToken as string)).then(
                    (res: any) => {
                        if (res.email === true) {
                            openSnackbar('res.message');
                            navigate('/');
                        }
                    }
                );
            }
        }
    }, [dispatch, router.query]);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required(t('This field is required')),
        password: Yup.string().required(t('This field is required')),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger, getValues } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = async ({ email, password, selectedBusiness }: any) => {
        setLoading(true);
        let role = router.pathname === '/admin/login' ? 'user' : 'user';
        if (isTeamLogin && selectedRole != '' && selectedRole != undefined) {
            role = selectedRole;
        }
        let adminLogin = {
            email: email?.toLowerCase(),
            password,
            role,
            // business: true,
        };
        let userLogin = {
            email: email?.toLowerCase(),
            password,
            role,
            businessId: selectedBusiness,
        };
        const res = await dispatch(
            userActions.login(router.pathname === '/admin/login' ? adminLogin : userLogin)
        );
        setLoading(false);
        if (res.user) {
            let _userData = res.user;

            if (res?.user_login === 'customer') {
                navigate(`/customers/${_userData?._id}`);
            } else if (
                router.pathname == '/admin/login' &&
                _userData.role == 'ADMIN' &&
                res?.user_login !== 'customer'
            ) {
                navigate('/admin');
            } else if (
                router.pathname == '/login' &&
                res?.user?.business_id?._id &&
                res?.user_login !== 'customer'
            ) {
                if (res?.businesses && !selectedBusiness) {
                    setOpendBusinessModel(true);
                    setAllUserBusiness({
                        business: res?.businesses,
                        selected: res?.user?.business_id?._id,
                    });
                } else {
                    dispatch(businessActions.getBusiness(res?.user?.business_id?._id)).then(
                        (res_1: any) => {
                            if (res_1.active === true) {
                                openSnackbar(t('Login Successfully'));
                                if (_userData != '' && _userData != undefined)
                                    if (_userData.permissions.dashboard) {
                                        navigate('/');
                                    } else if (_userData.permissions.calendar) {
                                        navigate('/calendar');
                                    } else if (_userData.permissions.sales) {
                                        navigate('/sales');
                                    } else if (_userData.permissions.team) {
                                        navigate('/team');
                                    } else if (_userData.permissions.customers) {
                                        navigate('/customers');
                                    } else if (_userData.permissions.business) {
                                        navigate('/settings');
                                    } else {
                                        navigate('/login');
                                        openSnackbar(t('Please Take permission for login'));
                                    }
                            } else {
                                localStorage.removeItem('user');
                                openSnackbar(
                                    t(
                                        'Login Failed, Please contact to admin for furthur information!'
                                    )
                                );
                            }
                        }
                    );
                }
            } else {
                setLoading(false);
                localStorage.removeItem('user');
                openSnackbar(t('Login Failed'));
            }
        } else {
            setLoading(false);
            openSnackbar(
                res?.response?.data?.message ? res?.response?.data?.message : t('Login Failed')
            );
        }
    };

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    return (
        <>
            <Wrapper>
                <Row>
                    <SideCover />
                    <Col lg={5} style={{ padding: '0' }}>
                        <CardRight>
                            <Inner>
                                <Heading>
                                    {router.pathname === '/admin/login'
                                        ? t('Admin Log In')
                                        : t('Log In')}{' '}
                                </Heading>
                            </Inner>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <InputGroup>
                                    <Input
                                        label={t('Email')}
                                        name="email"
                                        type="email"
                                        onChange={onChange}
                                        error={errors.email as any}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <Input
                                        type={t('Password')}
                                        name="password"
                                        label="Password"
                                        onChange={onChange}
                                        error={errors.password as any}
                                    />
                                </InputGroup>

                                {/* {router.pathname != '/admin/login' && (
                                    <CustomDiv>
                                        <Link to={'/forgot-password'}>{t('Forgot Password?')}</Link>
                                    </CustomDiv>
                                )} */}
                                {router.pathname != '/admin/login' && isTeamLogin && (
                                    <InputGroup>
                                        <Select
                                            name="selectedRole"
                                            disabled={true}
                                            value={
                                                selectedRole == 'serviceProvider'
                                                    ? 'Service Provider'
                                                    : selectedRole == 'others'
                                                    ? 'Others'
                                                    : selectedRole == 'teamMember'
                                                    ? 'Team Member'
                                                    : selectedRole == 'customer'
                                                    ? 'Customer'
                                                    : 'Please select your role'
                                            }
                                            options={roleList}
                                            onChange={e => {
                                                setSeletedRole(e);
                                            }}
                                        />
                                    </InputGroup>
                                )}
                                <Button
                                    type={'submit'}
                                    label={t('Log In')}
                                    width={'100% !important'}
                                    bgtype={'secondary'}
                                    disabled={openBusinessModel ? false : loading}
                                />

                                {isTeamLogin
                                    ? router.pathname != '/admin/login' && (
                                          <UserLink
                                              onClick={() => {
                                                  setIsTeamLogin(false);
                                              }}>
                                              {t('Admin Log In')}
                                          </UserLink>
                                      )
                                    : router.pathname != '/admin/login' && (
                                          <UserLink
                                              onClick={() => {
                                                  setIsTeamLogin(true);
                                              }}>
                                              {t('Team Log In')}
                                          </UserLink>
                                      )}
                            </form>
                            {/* <Inner justify={'center'}>
                                {isTeamLogin == false ? (
                                    <>
                                        {router.pathname != '/admin/login' && (
                                            <SignInText>
                                                Don't have an account?
                                                <Link to="/register">
                                                    <SignUpText className="text-primary-light">
                                                        {t('Create Account')}
                                                    </SignUpText>
                                                </Link>
                                            </SignInText>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Inner> */}
                            {router.pathname != '/admin/login' && <div></div>}
                        </CardRight>
                    </Col>
                </Row>
            </Wrapper>
            {openBusinessModel && userBusinesses?.business?.length && (
                <BusinessSelectionModal
                    businesses={userBusinesses?.business}
                    selected={userBusinesses?.selected}
                    onClose={() => setOpendBusinessModel(false)}
                    loading={loading}
                    onContinue={selectedBusiness =>
                        onSubmit({
                            email: getValues('email'),
                            password: getValues('password'),
                            selectedBusiness: selectedBusiness,
                        })
                    }
                />
            )}
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0;
    min-width: 100%;
    background-color: #fff;
`;

const CardRight = styled.div`
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Inner = styled.div<any>`
    margin-top: ${({ justify }) => justify && '1rem'};
    justify-content: ${({ justify }) => justify};
    display: flex;
    margin-bottom: 1rem;
`;
const Heading = styled.h3`
    color: #000;
    font-size: 2.6rem;
    font-weight: 600 !important;
    ${media.xs`
    margin-top:0;
	`}
    ${media.sm`
    margin-top:0;
	`}
	${media.md`
    margin-top:0;
	`}
`;

const SignInText = styled.span`
    display: flex;
    color: #000;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
`;
const SignUpText = styled.span`
    /* color: #0154ff !important; */
    font-weight: 800;
    padding-left: 0.5rem;
    margin: 0;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const UserLink = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: end;
    color: #000;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
`;

const CustomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0rem 0rem 0.5rem 0rem;
`;
export default Login;

/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: EditAppointmentDrawer
 * Description: This component represents the drawer for editing existing appointments.
 * It includes various functionalities such as updating appointment details, handling, date and time selection, managing appointment status, and interacting with the API.
 * handling conflicts, deleting appointments, displaying available slots for a specific service and service provider on the selected date, and managing coupon redemption.
 * Developed with React and integrates with various components for a seamless user experience.
 *
 * Props:
 * - id: string - Identifier for the appointment being edited.
 * - onChange: (appointment: IAppointment) => void - Callback function triggered when changes are made to the appointment. Receives the updated appointment details.
 * - onClose: (isUpdate?: any) => void - Callback function triggered when the edit appointment drawer is closed. Optionally receives a parameter indicating whether the appointment was updated.
 * - getAppointments: any - Function to load appointments again.
 * - parent?: any - Parameter for getting parent component details.
 * - waitingList?: any - Parameter for verifying is appointment is in waiting list.
 *
 */

import {
    Drawer,
    Input,
    Select,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    useSnackbar,
    LoaderOverlay,
    Switch,
} from '@components/common';
import React, { useEffect, useState } from 'react';
import SelectClient from '../newAppointmentDrawer/SelectClient';
import SelectService from '../newAppointmentDrawer/SelectService';

import { ICustomer } from 'interfaces/customer.interface';
import moment from 'moment';
import { appointmentActions } from 'actions/appointment';
import { useAppDispatch } from 'hooks/redux';
import { IAppointment } from 'interfaces/appointment.interface';

import as from 'components/appointments/Appointment.module.scss';
import styled from 'styled-components';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import Button from '@components/Button';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import SelectServiceProvider from '../newAppointmentDrawer/SelectServiceProvider';
import ConflictModal from '../modal/ConflictModal';
import DeleteRecurringModal from '../modal/DeleteRecurringModal';
import { api } from 'helpers/auth-axios';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import CompletePayment from './CompletePayment';
import CustomerModal from '../modal/CustomerModal';
import { useTranslation } from 'react-i18next';
import AppointmentInvoice from '../newAppointmentDrawer/AppointmentInvoice';
import RedeemModel from '../modal/RedeemModel';
import ReportModel from '../modal/ReportModel';
import { CustomDiv } from '@components/settings/businessServices/BusinessServices';
import ServiceAssets from './ServiceAssets';
import { palette } from 'styled/common';

const EditAppointmentDrawer = ({
    id,
    onChange,
    onClose,
    getAppointments,
    parent,
    waitingList,
}: {
    id: string;
    onChange(appointment: IAppointment): void;
    onClose(isUpdate?: any): void;
    getAppointments: any;
    parent?: any;
    waitingList?: any;
}) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [appointment, setAppointment] = useState<IAppointment>();
    const [loading, setLoading] = useState(false);
    const [appointmentStatus, setAppointmentStatus] = useState(false);
    const [active, setActive] = useState(false);
    const [customerView, setCustomerView] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
    const [selectedServiceProvider, setSelectedServiceProvider] = useState<IServiceProvider>();
    const [serviceView, setServiceView] = useState(false);
    const [serviceProviderView, setServiceProviderView] = useState(false);
    const [selectedService, setSelectedService] = useState<any>();
    const [showRecurringModal, setShowRecurringModal] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [conflictDates, setConflictDates] = useState<string[]>([]);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const [appointmentNote, setAppointmentNote] = useState<string>();
    const [customerNote, setCustomerNote] = useState<string>();
    const [start, setStart] = useState<any>();
    const [end, setEnd] = useState<any>();
    const [status, setStatus] = useState<any>();
    const [overbooking, setOverbooking] = useState(false);
    const [providerId, setProviderId] = useState<any>();
    const [recurringId, setRecurringId] = useState<any>();
    const [startDate, setStartDate] = useState<any>();
    const [enddate, setEndDate] = useState<any>();
    const [createAt, setCreateAt] = useState<any>();
    const [updateAt, setUpdateAt] = useState<any>();
    const [platform, setPlatForm] = useState<any>();
    const [durationField, setDurationFieldOpen] = useState<boolean>(false);
    const [serviceDurationHours, setServiceDurationHours] = useState<any>(0);
    const [durationHours, setDurationHours] = useState<any>();
    const [serviceDurationMinutes, setServiceDurationMinutes] = useState<any>(0);
    const [durationMinutes, setDurationMinutes] = useState<any>();
    const [availableSlot, setAvailableSlot] = useState<any[]>([]);
    const [dropDownFilter, setDropdownFilter] = useState<any>();
    const [payment, setPayment] = useState<any>();
    const [chargeModel, setChargeModel] = useState<boolean>(false);
    const [is_vip, setIs_vip] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [customerModel, setCustomerModel] = useState(false);
    const [customerEditModel, setCustomerEditModel] = useState(false);
    const [appointmentInvoiceModel, setAppointmentInvoiceModel] = useState(false);
    const [appointmentData, setAppointmentData] = useState<any>();
    const [redeemModel, setRedeemModel] = useState(false);
    const [coupenData, setCoupenData] = useState<any>('');
    const [totalValue, setTotalValue] = useState<any>('');
    const [coupenUsed, setCoupenUsed] = useState(false);
    const [redeemCoupen, setRedeemCoupen] = useState('');
    const [coupenModelData, setCoupenModelData] = useState<any>('');
    const [reportModel, setReportModel] = useState(false);
    const [is_video, setIs_video] = useState(false);
    const [appointmentSlots, setAppointmentSlots] = useState<any[]>([]);
    const [is_waiting, setIs_Waiting] = useState(false);
    const [viewAssets, setViewAssets] = useState(false);
    const [isStatus, setIsStatus] = useState(false);
    const [cancellationReason, setCancellationReason] = useState<any>('');
    const [reasonError, setReasonError] = useState<any>('');
    const [appointmentAssets, setAppointmentAssets] = useState<any[]>([]);

    const dispatch = useAppDispatch();
    const defaultDuration = serviceDurationHours * 60 + serviceDurationMinutes;
    const customDuration = durationHours * 60 + durationMinutes;
    const appointmentTime: { label: string; value: any }[] = [];

    let time = moment(startTime).format('HH:mm');
    let date = moment(startDate ? startDate : start).format('YYYY-MM-DD');
    let todayDate = moment(start).format('YYYY-MM-DD');

    useEffect(() => {
        dispatch(appointmentActions.getAppoinmentById(id)).then((res: any) => {
            if (res.error) return;
            setRecurringId(res.recurring);
            const appointment = res as IAppointment;
            setUpdateAppointment(appointment);
            setPlatForm(appointment.platform);
            setAppointmentData(res);
            setTimeout(() => setActive(true), 20);
        });
    }, []);

    useEffect(() => {
        if (overbooking) onSubmit(appointmentAssets);
    }, [overbooking]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getAvailableSlot();
    }, [startDate, defaultDuration, customDuration, start, is_vip]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        dropDownValue();
    }, [startDate, defaultDuration, customDuration, start, selectedService, dropDownFilter]);

    const getAppointmentLabel = (status: string | undefined) => {
        if (status === 'confirmed' || status === 'finished') {
            return as.appointment_statusSuccess;
        } else if (status === 'Late') {
            return as.appointment_statusWarning;
        } else if (status === 'cancelled') {
            return as.appointment_statusDanger;
        } else {
            return as.appointment_statusGray;
        }
    };

    const DurationHours: { label: string; value: number }[] = [];
    for (let i = 0; i < 24; i++) {
        DurationHours.push({
            label: `${i}h`,
            value: i,
        });
    }

    const DurationMinutes: { label: string; value: number }[] = [];
    for (let i = 0; i < 12; i++) {
        DurationMinutes.push({
            label: `${i * 5}min`,
            value: i * 5,
        });
    }

    const event = {
        id,
        start,
        end,
        customer: selectedCustomer,
        service: selectedService,
        serviceProvider: selectedServiceProvider,
    };

    const getAppointmentStatuses = (status: string | undefined) => {
        if (_userData?.user_login == 'customer' && status === 'confirmed') {
            return [{ key: t('cancelled'), value: 'cancelled' }];
        }
        if (status === 'confirmed' || status === 'cancelled') {
            return [
                { key: t('confirmed'), value: 'confirmed' },
                { key: t('cancelled'), value: 'cancelled' },

                { key: t('Checked-in'), value: 'Checked-in' },

                { key: t('No-show'), value: 'No-show' },
                { key: t('Late'), value: 'Late' },
                { key: t('Completed'), value: 'Completed' },
            ];
        } else {
            return [
                { key: t('finished'), value: 'finished' },
                ,
                { key: t('No-show'), value: 'No-show' },
                { key: t('Late'), value: 'Late' },
            ];
        }
    };

    if (is_vip === true || waitingList === true) {
        let vipTime: any[] = [];
        let duration = durationField === false ? defaultDuration : customDuration;
        for (let i: any = 0; i < 24; i++) {
            for (let j = 0; j < 12; j++) {
                vipTime.push(moment({ hour: i, minute: j * 5 }).format('HH:mm'));
            }
        }
        let tmpTime: any[] = [];
        vipTime.map((res: any, index: any) => {
            let customTime = moment(res, 'HH:mm').add(5, 'minute').format('HH:mm');
            let tmpTime1 = moment(res, 'HH:mm').add(duration, 'minute').format('HH:mm');
            let format = 'HH:mm';
            if (appointmentSlots?.includes(res) || appointmentSlots?.includes(customTime)) {
                for (let i = 0; i < appointmentSlots?.length; i++) {
                    let mTime = moment(appointmentSlots[i], format),
                        beforeTime = moment(res, format),
                        afterTime = moment(tmpTime1, format);
                    if (
                        mTime.isBetween(beforeTime, afterTime) &&
                        mTime.isBetween(beforeTime, afterTime)
                    ) {
                        if (tmpTime[index - 1] != -1) {
                            tmpTime.splice(index - 1, 1);
                        }
                    }
                }
            } else {
                tmpTime.push(res);
            }
        });
        tmpTime.forEach((item: any) => {
            if (date === todayDate) {
                item !== time &&
                    appointmentTime.push({
                        label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            'HH:mm'
                        ),
                    });
            } else {
                appointmentTime.push({
                    label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                    ),
                    value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        'HH:mm'
                    ),
                });
            }
        });
    } else {
        availableSlot?.length &&
            availableSlot.forEach((item: any) => {
                if (date === todayDate) {
                    item >= time &&
                        appointmentTime.push({
                            label: moment(
                                moment().format() + ' ' + item,
                                'YYYY-MM-DD HH:mm:ss'
                            ).format(
                                _userData?.user?.business_id.time_select === '12'
                                    ? 'hh:mm a'
                                    : 'HH:mm'
                            ),
                            value: moment(
                                moment().format() + ' ' + item,
                                'YYYY-MM-DD HH:mm:ss'
                            ).format('HH:mm'),
                        });
                } else {
                    appointmentTime.push({
                        label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            'HH:mm'
                        ),
                    });
                }
            });
    }

    const setUpdateAppointment = (appointment: IAppointment) => {
        const appointmentDuration: any = appointment!.duration;
        setProviderId(appointment.serviceProvider._id);
        setAppointment(appointment);
        setAppointmentNote(appointment.appointment_note);
        setCustomerNote(appointment.customer_note);
        setSelectedCustomer(appointment.customer);
        setSelectedService(appointment.service);
        setSelectedServiceProvider(appointment.serviceProvider);
        setStart(appointment.booked_from_iso);
        setStartTime(appointment.booked_from_iso);
        setEnd(appointment.booked_till_iso);
        setStartDate(appointment.booked_from_iso);
        setEndDate(appointment.booked_till_iso);
        setCreateAt(moment(appointment.created_at).format('DD.MM.YYYY, HH:mm'));
        setUpdateAt(moment(appointment.updated_at).format('DD.MM.YYYY, HH:mm'));
        setServiceDurationHours(
            appointmentDuration >= 60 ? Math.floor(appointmentDuration / 60) : ''
        );
        setDurationHours(appointmentDuration >= 60 ? Math.floor(appointmentDuration / 60) : '');
        setDurationMinutes(appointmentDuration % 60);
        setServiceDurationMinutes(appointmentDuration % 60);
        setDropdownFilter(moment(appointment.booked_from_iso).format('HH:mm'));
        setPayment(appointment.payment);
        setIs_vip(appointment?.is_vip);
        setTotalValue(appointment.total_value);
        setCoupenUsed(appointment.coupen_used);
        setIs_video(appointment?.is_video_appointment);
        setStatus(appointment?.status);
        setCancellationReason(appointment?.cancelation_reason);
        setAppointmentAssets(appointment?.assets ?? []);
    };

    //get appointment details
    const getAppointmentById = (id: any) => {
        api.get(`/appointments/get/${id}`).then((res: any) => {
            setAppointment(res.data);

            setPlatForm(res?.data?.platform);
            setTimeout(() => setActive(true), 20);
        });
    };

    const updateEvent = (data: any) => {
        onChange({
            ...event,
            ...data,
        });
    };

    let totalValueDrawer: any =
        appointment?.total?.length > 1
            ? selectedService?.name != appointment?.service?.name
                ? selectedService?.my_price
                : appointment?.total?.substring(1, appointment?.total?.length)
            : 0;
    const calculateInPercentage = (totalValueDrawer / 100) * parseInt(coupenData?.percentage);
    const afterPercentageValue = totalValueDrawer - calculateInPercentage;

    //on Update appointment
    const onSubmit = (assets: any, isClose = true, isAssetsUpdate = false) => {
        if (status && status == 'cancelled' && cancellationReason?.trim()?.length == 0) {
            setReasonError(t('Cancellation reason is required'));

            return;
        } else {
            const startTime =
                moment(startDate ? startDate : start).format('YYYY-MM-DD') +
                'T' +
                moment(start).format('HH:mm') +
                ':00';
            const endTime =
                moment(enddate ? enddate : end).format('YYYY-MM-DD') +
                'T' +
                moment(start)
                    .add(durationField === false ? defaultDuration : customDuration, 'minutes')
                    .format('HH:mm') +
                ':00';

            const updatedAppointment = {
                booked_from_iso: startTime ? moment(startTime).format() : moment(start).format(),
                booked_till_iso: endTime ? moment(endTime).format() : moment(end).format(),
                booked_from: startTime ? moment(startTime).format() : moment(start).format(),
                booked_till: endTime ? moment(endTime).format() : moment(end).format(),
                appointment_note: appointmentNote ? appointmentNote : '',
                serviceProvider: selectedServiceProvider ? selectedServiceProvider?._id : null,
                customer: selectedCustomer ? selectedCustomer?._id : null,
                service: selectedService!._id,
                total:
                    coupenData?.in_percentage === true
                        ? _userData.user.business_id.currency + afterPercentageValue
                        : coupenData?.price
                        ? coupenData?.price > selectedService?.my_price
                            ? '0'
                            : _userData.user.business_id.currency +
                              (selectedService?.my_price - coupenData?.price)
                        : _userData.user.business_id.currency + selectedService?.my_price,
                total_value: parseInt(selectedService?.my_price),
                customer_note: customerNote ? customerNote : '',
                status: status,
                duration: durationField === false ? defaultDuration : customDuration,
                coupen_used: coupenData?.price ? true : false,
                discount:
                    coupenData?.in_percentage === true ? calculateInPercentage : coupenData?.price,
                is_video_appointment: is_video,
                is_waiting: appointment?.is_waiting,
                is_vip: is_vip,
                approve_waiting: is_waiting,
                isStatus: isStatus,
                cancelation_reason: status == 'cancelled' ? cancellationReason : '',
                assets: assets,
            };

            setLoading(true);
            dispatch(appointmentActions.updateAppointmentById(id, updatedAppointment))
                .then((res: any) => {
                    setLoading(false);
                    if (res.error) {
                        return;
                    }

                    if (isClose) {
                        openSnackbar(t('Appointment updated successfully!'));
                        setActive(false);
                        onClose(true);
                    }

                    if (isAssetsUpdate) {
                        getAppointmentById(id);
                    }
                })
                .catch(e => {
                    setLoading(false);
                    if (e?.response) {
                        const response = e?.response?.data?.response;
                        openSnackbar(response?.message);
                        if (response?.dates) {
                            setConflictDates(response?.dates);
                            setShowConflictModal(true);
                        } else {
                            onClose();
                        }
                    }
                });
        }
    };

    const singleAppointmentDelete = () => {
        setLoading(true);
        api.delete(`/appointments/${id}`)
            .then(() => {
                setLoading(false);
                openSnackbar(t('Appointment deleted successfully!'));
                getAppointments();
            })
            .catch((res: any) => {
                openSnackbar(t('Failed to delete Appointment!'));
            });
    };

    const appointmentStatusOpen = () => {
        if (appointmentStatus) {
            setAppointmentStatus(false);
        } else {
            setAppointmentStatus(true);
        }
    };

    const doSetStatus = (status: any) => {
        setStatus(status);
        appointmentStatusOpen();
        setIsStatus(true);
    };

    const hiddenDateCalendar = (e: any) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    const getAvailableSlot = (start_date = '') => {
        if (selectedServiceProvider !== undefined) {
            let date: any = moment(startDate ? startDate : start).format();
            let duration = durationField === false ? defaultDuration : customDuration;
            api.post(`appointments/available_slots/${selectedServiceProvider._id}`, {
                date: date,
                duration: duration,
                calendar: parent === 'customise' ? 'customise' : undefined,
            }).then((res: any) => {
                setAvailableSlot(res.data?.slots);
                let unique = [...new Set(res.data?.appointmentSlots)];
                setAppointmentSlots(unique.sort());
            });
        }
    };

    const dropDownValue = () => {
        let time = '';
        appointmentTime.forEach((val: any) => {
            const value = moment(start).format(
                _userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
            );

            if (value.indexOf('am') != -1 || value.indexOf('pm') != -1) {
                time = val.label;
            } else {
                time = val.value;
            }
            if (time == value) {
                setDropdownFilter(val.value);
                return;
            } else {
                return appointmentTime[0].label;
            }
        });
    };

    const paymentButtons =
        (_userData.user.business_id?.booking_without_payment === false &&
            selectedServiceProvider?.stripe_account_id !== null &&
            selectedServiceProvider?.stripe_account_verified === true) ||
        (_userData.user.business_id?.booking_without_payment === false &&
            _userData.user.business_id?.stripe_account_verified === true);

    // get coupen details
    const submitCoupen = () => {
        api.post(`/gift-card/get`, { redeem: redeemCoupen })
            .then((res: any) => {
                setCoupenModelData(res.data);
                setRedeemModel(true);
            })
            .catch((e: any) => {
                if (e?.response) {
                    const response = e?.response?.data?.response;
                    openSnackbar(response?.message);
                }
            });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Drawer
                            custom={true}
                            active={active}
                            onClose={() => {
                                if (!loading) {
                                    setActive(false);
                                    onClose();
                                }
                            }}>
                            {!customerView &&
                                !serviceView &&
                                !serviceProviderView &&
                                !chargeModel &&
                                !appointmentInvoiceModel &&
                                !redeemModel &&
                                !reportModel &&
                                !viewAssets && (
                                    <AppointmentContent>
                                        <HeaderContainer>
                                            <ToggleButton>
                                                <Dropdown>
                                                    <DropdownToggle onClick={appointmentStatusOpen}>
                                                        <StatusButton
                                                            onClick={appointmentStatusOpen}
                                                            className={`${getAppointmentLabel(
                                                                status
                                                                    ? status
                                                                    : appointment?.status
                                                            )}`}>
                                                            {status
                                                                ? t(status)
                                                                : t(appointment?.status)}
                                                            <DownIcon className="fas fa-chevron-down"></DownIcon>
                                                        </StatusButton>
                                                    </DropdownToggle>
                                                    {appointmentStatus && (
                                                        <DropdownMenu>
                                                            {getAppointmentStatuses(
                                                                appointment?.status
                                                            ).map(status => {
                                                                return (
                                                                    <DropdownItem
                                                                        key={status?.key}
                                                                        onClick={() => {
                                                                            doSetStatus(
                                                                                status?.value
                                                                            );
                                                                        }}>
                                                                        <Status
                                                                            className={`${
                                                                                as.appointment_statusLabel
                                                                            } ${getAppointmentLabel(
                                                                                status?.value
                                                                            )}`}>
                                                                            {status?.key}
                                                                        </Status>
                                                                    </DropdownItem>
                                                                );
                                                            })}
                                                        </DropdownMenu>
                                                    )}
                                                </Dropdown>
                                            </ToggleButton>
                                            <CustomButton style={{ gap: '1rem', display: 'flex' }}>
                                                {appointment?.reportId && (
                                                    <i
                                                        className="fad fa-exclamation-triangle"
                                                        onClick={() => setReportModel(true)}
                                                        style={{
                                                            color: palette.primary,
                                                            fontSize: '1.4rem',
                                                            cursor: 'pointer',
                                                        }}></i>
                                                )}
                                                {appointment?.recurring ? (
                                                    ''
                                                ) : _userData?.user_login == 'user' ? (
                                                    <i
                                                        className="fad fa-trash-alt"
                                                        onClick={() =>
                                                            setShowAppointmentModal(true)
                                                        }
                                                        style={{
                                                            color: palette.primary,
                                                            fontSize: '1.4rem',
                                                            cursor: 'pointer',
                                                        }}></i>
                                                ) : (
                                                    ''
                                                )}
                                            </CustomButton>
                                            <CloseButton
                                                onClick={() => {
                                                    if (!loading) {
                                                        setActive(false);
                                                    }
                                                }}>
                                                <Icon className="fal fa-times"></Icon>
                                            </CloseButton>
                                        </HeaderContainer>
                                        {appointment?.is_waiting && (
                                            <WaitingListText>
                                                {t('Waiting list appointment')}
                                            </WaitingListText>
                                        )}
                                        <DrawerBody>
                                            {selectedServiceProvider ? (
                                                <div>
                                                    {_userData.user_login == 'user' ? (
                                                        <ServiceProviderContent
                                                            onClick={() => {
                                                                !loading &&
                                                                    setServiceProviderView(true);
                                                            }}>
                                                            <Circle>
                                                                <NameCircle
                                                                    src={`${process.env.REACT_APP_PROFILE_URL}${selectedServiceProvider?.photo}`}></NameCircle>
                                                            </Circle>
                                                            <CustomerContent>
                                                                <Name>
                                                                    {selectedServiceProvider.name}
                                                                </Name>
                                                                <Email>
                                                                    {selectedServiceProvider.email}
                                                                </Email>
                                                            </CustomerContent>
                                                            <div>
                                                                <Icons className="fal fa-chevron-right"></Icons>
                                                            </div>
                                                        </ServiceProviderContent>
                                                    ) : (
                                                        <ServiceProviderContent>
                                                            <Circle>
                                                                <NameCircle
                                                                    src={`${process.env.REACT_APP_PROFILE_URL}${selectedServiceProvider?.photo}`}></NameCircle>
                                                            </Circle>
                                                            <CustomerContent>
                                                                <Name>
                                                                    {selectedServiceProvider.name}
                                                                </Name>
                                                                <Email>
                                                                    {selectedServiceProvider.email}
                                                                </Email>
                                                            </CustomerContent>
                                                            <div>
                                                                <Icons className="fal fa-chevron-right"></Icons>
                                                            </div>
                                                        </ServiceProviderContent>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <ServiceProviderContent
                                                        onClick={() => {
                                                            !loading &&
                                                                setServiceProviderView(true);
                                                        }}>
                                                        <Circle>
                                                            <NameCircle></NameCircle>
                                                        </Circle>
                                                        <Label>
                                                            {t('Select a serviceProvider')}
                                                        </Label>
                                                        <div>
                                                            <Icons className="fal fa-plus"></Icons>
                                                        </div>
                                                    </ServiceProviderContent>
                                                </div>
                                            )}
                                            {selectedService ? (
                                                <SelectServiceContent
                                                    onClick={() => {
                                                        _userData.user_login !== 'customer' &&
                                                            !loading &&
                                                            setServiceView(true);
                                                    }}>
                                                    <ServiceBorder
                                                        className={`service-color-${selectedService.color}--bd`}></ServiceBorder>
                                                    <ServiceDetails>
                                                        <NameBody>
                                                            <ServiceName>
                                                                {selectedService.name}
                                                            </ServiceName>
                                                            <ServiceDuration>
                                                                {appointment!.duration}min
                                                            </ServiceDuration>
                                                        </NameBody>
                                                        <PriceBody>
                                                            <ServicePrice>
                                                                {coupenData?.in_percentage === true
                                                                    ? _userData.user.business_id
                                                                          .currency +
                                                                      afterPercentageValue?.toFixed(
                                                                          2
                                                                      )
                                                                    : coupenData?.price
                                                                    ? coupenData?.price >
                                                                      selectedService?.my_price
                                                                        ? '0'
                                                                        : _userData.user.business_id
                                                                              .currency +
                                                                          (selectedService?.my_price -
                                                                              coupenData?.price)
                                                                    : appointment?.total}
                                                            </ServicePrice>
                                                            <div>
                                                                <Icons className="fal fa-chevron-right"></Icons>
                                                            </div>
                                                        </PriceBody>
                                                    </ServiceDetails>
                                                </SelectServiceContent>
                                            ) : (
                                                <SelectServiceBody
                                                    onClick={() => {
                                                        setServiceView(true);
                                                    }}>
                                                    <ServiceHeader>
                                                        {t('Select service')}
                                                    </ServiceHeader>
                                                    <div>
                                                        <Icons className="fal fa-chevron-right"></Icons>
                                                    </div>
                                                </SelectServiceBody>
                                            )}
                                            {selectedService?.assets?.length ? (
                                                <SelectServiceContent
                                                    onClick={() => {
                                                        _userData.user_login !== 'customer' &&
                                                            !loading &&
                                                            setViewAssets(true);
                                                    }}>
                                                    <ServiceBorder
                                                        className={`service-color-${selectedService.color}--bd`}></ServiceBorder>
                                                    <ServiceDetails>
                                                        <NameBody>
                                                            <ServiceName>{t('Assets')}</ServiceName>
                                                            <ServiceDuration>
                                                                {selectedService?.assets?.map(
                                                                    (e: any, index: any) => (
                                                                        <React.Fragment key={index}>
                                                                            {e.name}
                                                                            {index <
                                                                                selectedService
                                                                                    ?.assets
                                                                                    ?.length -
                                                                                    1 && ', '}
                                                                        </React.Fragment>
                                                                    )
                                                                )}
                                                            </ServiceDuration>
                                                        </NameBody>
                                                        <PriceBody>
                                                            <div>
                                                                <Icons className="fal fa-chevron-right"></Icons>
                                                            </div>
                                                        </PriceBody>
                                                    </ServiceDetails>
                                                </SelectServiceContent>
                                            ) : (
                                                ''
                                            )}
                                            {appointment?.service?.is_video && (
                                                <CustomDiv
                                                    style={{
                                                        marginTop: '0.3rem',
                                                        marginBottom: '0.3rem',
                                                    }}>
                                                    <CustomSpan style={{ fontSize: '1rem' }}>
                                                        {t('video appointment')}
                                                    </CustomSpan>
                                                    <Switch
                                                        value={is_video}
                                                        disabled={loading}
                                                        onChange={() => {
                                                            setIs_video(!is_video);
                                                        }}
                                                        isSmall={true}
                                                    />
                                                </CustomDiv>
                                            )}

                                            {selectedCustomer ? (
                                                <div>
                                                    <SelectCustomer>
                                                        <div
                                                            style={{ display: 'flex' }}
                                                            onClick={() => {
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                    !loading &&
                                                                    setCustomerView(true);
                                                            }}>
                                                            <Circle>
                                                                <NameCircle
                                                                    src={`${process.env.REACT_APP_PROFILE_URL}${selectedCustomer?.photo}`}></NameCircle>
                                                            </Circle>
                                                            <CustomerContent>
                                                                <Name>
                                                                    {selectedCustomer.firstname +
                                                                        ' ' +
                                                                        selectedCustomer.lastname}
                                                                </Name>
                                                                <Email>
                                                                    {selectedCustomer.phone
                                                                        ? selectedCustomer.phone
                                                                        : selectedCustomer.email}
                                                                </Email>
                                                            </CustomerContent>
                                                        </div>

                                                        <IconsDiv>
                                                            <Icons
                                                                className="fal fa-edit"
                                                                onClick={() =>
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                    setCustomerEditModel(true)
                                                                }></Icons>
                                                            <Icons
                                                                className="fal fa-plus"
                                                                onClick={() =>
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                    setCustomerModel(true)
                                                                }></Icons>
                                                            <Icons
                                                                className="fal fa-chevron-right"
                                                                onClick={() => {
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                        setCustomerView(true);
                                                                }}></Icons>
                                                        </IconsDiv>
                                                    </SelectCustomer>
                                                </div>
                                            ) : (
                                                <div>
                                                    <AppointmentCustomer>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                            onClick={() => {
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                    setCustomerView(true);
                                                            }}>
                                                            <Circle>
                                                                <NameCircle></NameCircle>
                                                            </Circle>
                                                            <Label>{t('Select a client')}</Label>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}>
                                                            <Icons
                                                                className="fal fa-plus"
                                                                onClick={() =>
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                    setCustomerModel(true)
                                                                }></Icons>
                                                            <Icons
                                                                className="fal fa-chevron-right"
                                                                onClick={() => {
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                        setCustomerView(true);
                                                                }}></Icons>
                                                        </div>
                                                    </AppointmentCustomer>
                                                </div>
                                            )}

                                            <FormContent>
                                                <FormDetails>
                                                    <DatesBody>
                                                        <div></div>
                                                        <CustomButton>
                                                            {appointment?.recurring &&
                                                                _userData?.user_login == 'user' && (
                                                                    <RepeateButton
                                                                        onClick={() =>
                                                                            !loading &&
                                                                            setShowRecurringModal(
                                                                                true
                                                                            )
                                                                        }>
                                                                        {t('Recurring')}
                                                                        <RepeateIcon className="fas fa-repeat"></RepeateIcon>
                                                                    </RepeateButton>
                                                                )}
                                                        </CustomButton>
                                                    </DatesBody>
                                                    {_userData.user_login !== 'customer' && (
                                                        <InputGroup>
                                                            <InputContent>
                                                                <Col
                                                                    lg={12}
                                                                    style={{
                                                                        display: 'flex',
                                                                        padding: '0',
                                                                    }}>
                                                                    <Col lg={6}>
                                                                        <FormDate>
                                                                            <CalenderDate>
                                                                                {startDate
                                                                                    ? moment(
                                                                                          startDate
                                                                                      ).format(
                                                                                          'ddd, MMM DD'
                                                                                      )
                                                                                    : moment(
                                                                                          start
                                                                                      ).format(
                                                                                          'ddd, MMM DD'
                                                                                      )}
                                                                            </CalenderDate>
                                                                            <input
                                                                                type="date"
                                                                                min={
                                                                                    new Date()
                                                                                        .toISOString()
                                                                                        .split(
                                                                                            'T'
                                                                                        )[0]
                                                                                }
                                                                                value={moment(
                                                                                    startDate
                                                                                ).format(
                                                                                    'YYYY-MM-DD'
                                                                                )}
                                                                                onChange={(
                                                                                    e: any
                                                                                ) =>
                                                                                    hiddenDateCalendar(
                                                                                        e
                                                                                    )
                                                                                }
                                                                            />
                                                                        </FormDate>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Select
                                                                            label={t('Start')}
                                                                            disabled={true}
                                                                            readOnly={loading}
                                                                            value={
                                                                                availableSlot?.length
                                                                                    ? moment(
                                                                                          moment().format() +
                                                                                              ' ' +
                                                                                              dropDownFilter,
                                                                                          'YYYY-MM-DD HH:mm:ss'
                                                                                      ).format(
                                                                                          _userData
                                                                                              ?.user
                                                                                              ?.business_id
                                                                                              .time_select ===
                                                                                              '12'
                                                                                              ? 'hh:mm a'
                                                                                              : 'HH:mm'
                                                                                      )
                                                                                    : moment(
                                                                                          appointment?.booked_from_iso
                                                                                      ).format(
                                                                                          _userData
                                                                                              ?.user
                                                                                              ?.business_id
                                                                                              .time_select ===
                                                                                              '12'
                                                                                              ? 'hh:mm a'
                                                                                              : 'HH:mm'
                                                                                      )
                                                                            }
                                                                            options={
                                                                                appointmentTime
                                                                            }
                                                                            onChange={(
                                                                                val: string
                                                                            ) => {
                                                                                const newStart =
                                                                                    moment(
                                                                                        start
                                                                                    ).format(
                                                                                        'YYYY-MM-DD'
                                                                                    ) +
                                                                                    'T' +
                                                                                    val +
                                                                                    ':00';

                                                                                setStart(newStart);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Col>
                                                            </InputContent>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <InputGroup>
                                                                        <Select
                                                                            disabled={true}
                                                                            label={t('Hour(s)')}
                                                                            readOnly={
                                                                                !durationField
                                                                            }
                                                                            options={DurationHours}
                                                                            value={
                                                                                durationField ===
                                                                                false
                                                                                    ? serviceDurationHours ===
                                                                                      ''
                                                                                        ? '0h'
                                                                                        : serviceDurationHours +
                                                                                          'h'
                                                                                    : durationHours ===
                                                                                      ''
                                                                                    ? '0h'
                                                                                    : durationHours +
                                                                                      'h'
                                                                            }
                                                                            onChange={(
                                                                                val: string
                                                                            ) => {
                                                                                setDurationHours(
                                                                                    parseInt(val)
                                                                                );
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <InputGroup>
                                                                        <Select
                                                                            label={t('Minutes')}
                                                                            readOnly={
                                                                                !durationField
                                                                            }
                                                                            disabled={true}
                                                                            options={
                                                                                DurationMinutes
                                                                            }
                                                                            value={
                                                                                durationField ===
                                                                                false
                                                                                    ? serviceDurationMinutes +
                                                                                      t('min')
                                                                                    : durationMinutes +
                                                                                      t('min')
                                                                            }
                                                                            onChange={(
                                                                                val: string
                                                                            ) => {
                                                                                setDurationMinutes(
                                                                                    parseInt(val)
                                                                                );
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                            <ToggleDurationButton>
                                                                <div>
                                                                    <CustomSpan>
                                                                        {t('VIP Appointment')}
                                                                    </CustomSpan>
                                                                    <Switch
                                                                        value={is_vip}
                                                                        isSmall={true}
                                                                        onChange={() => {
                                                                            setIs_vip(!is_vip);
                                                                        }}
                                                                    />{' '}
                                                                </div>
                                                                <div>
                                                                    <CustomSpan>
                                                                        {t('Custom Duration')}
                                                                    </CustomSpan>
                                                                    <Switch
                                                                        value={durationField}
                                                                        onChange={() => {
                                                                            setDurationFieldOpen(
                                                                                !durationField
                                                                            );
                                                                        }}
                                                                        isSmall={true}
                                                                    />
                                                                </div>
                                                            </ToggleDurationButton>
                                                        </InputGroup>
                                                    )}
                                                    {_userData.user_login !== 'customer' && (
                                                        <InputGroup>
                                                            <Input
                                                                label={t(
                                                                    'Appointment note (only visible to staff)'
                                                                )}
                                                                value={appointmentNote}
                                                                onChange={({
                                                                    value,
                                                                }: {
                                                                    value: string;
                                                                }) => {
                                                                    setAppointmentNote(value);
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    )}

                                                    <InputGroup>
                                                        <Input
                                                            label={`${
                                                                _userData.user_login !== 'customer'
                                                                    ? t('Message from client')
                                                                    : t(
                                                                          'Message to Service Provider'
                                                                      )
                                                            }`}
                                                            value={customerNote}
                                                            readOnly={true}
                                                            onChange={({
                                                                value,
                                                            }: {
                                                                value: string;
                                                            }) => {
                                                                setCustomerNote(value);
                                                            }}
                                                        />
                                                    </InputGroup>

                                                    {status == 'cancelled' ? (
                                                        <InputGroup>
                                                            <Input
                                                                label={t('Cancellation Reason')}
                                                                value={cancellationReason}
                                                                onChange={({
                                                                    value,
                                                                }: {
                                                                    value: string;
                                                                }) => {
                                                                    setCancellationReason(value);
                                                                }}
                                                                error={
                                                                    reasonError &&
                                                                    !cancellationReason
                                                                        ? { message: reasonError }
                                                                        : undefined
                                                                }
                                                            />
                                                        </InputGroup>
                                                    ) : (
                                                        ''
                                                    )}

                                                    <>
                                                        {coupenUsed === false &&
                                                            coupenData?.price === undefined && (
                                                                <>
                                                                    <InputGroup
                                                                        style={{
                                                                            display: 'flex',
                                                                            gap: '1rem',
                                                                            justifyContent:
                                                                                'space-between',
                                                                        }}>
                                                                        <Input
                                                                            label={t(
                                                                                `Have any discount voucher ?`
                                                                            )}
                                                                            value={redeemCoupen}
                                                                            onChange={({
                                                                                value,
                                                                            }: {
                                                                                value: string;
                                                                            }) => {
                                                                                setRedeemCoupen(
                                                                                    value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            bgtype={'secondary'}
                                                                            ifClicked={() =>
                                                                                redeemCoupen
                                                                                    ? submitCoupen()
                                                                                    : openSnackbar(
                                                                                          'Please enter coupon first!'
                                                                                      )
                                                                            }
                                                                            label={t(
                                                                                'Redeem'
                                                                            )}></Button>
                                                                    </InputGroup>
                                                                </>
                                                            )}
                                                    </>
                                                    {appointment?.is_waiting && (
                                                        <div>
                                                            <CustomSpan>
                                                                {t(
                                                                    'Remove from waiting list to normal appointment'
                                                                )}
                                                            </CustomSpan>
                                                            <Switch
                                                                value={is_waiting}
                                                                onChange={val => {
                                                                    if (val === true) {
                                                                        openSnackbar(
                                                                            t(
                                                                                'Please cancel scheduled appointment first to remove this appointment into normal'
                                                                            )
                                                                        );
                                                                    }
                                                                    setIs_Waiting(!is_waiting);
                                                                }}
                                                                isSmall={true}
                                                            />
                                                        </div>
                                                    )}
                                                    <PlatformDiv>
                                                        {_userData.user_login !== 'customer' && (
                                                            <TotalPriceBody>
                                                                <PlatForm>{t('Platform')}</PlatForm>
                                                                <PlatFormText>
                                                                    {platform}
                                                                </PlatFormText>
                                                            </TotalPriceBody>
                                                        )}

                                                        <TotalPriceBody>
                                                            <PlatForm>{t('Payment')}</PlatForm>
                                                            <PlatFormText>
                                                                {payment == null
                                                                    ? t('unpaid')
                                                                    : t('paid')}
                                                            </PlatFormText>
                                                        </TotalPriceBody>
                                                        <TotalPriceBody>
                                                            <PriceHeader>{t('Total')}</PriceHeader>
                                                            <TotalPrice>
                                                                {coupenData?.in_percentage === true
                                                                    ? _userData.user.business_id
                                                                          .currency +
                                                                      afterPercentageValue?.toFixed(
                                                                          2
                                                                      )
                                                                    : coupenData?.price
                                                                    ? coupenData?.price > totalValue
                                                                        ? '0'
                                                                        : _userData.user.business_id
                                                                              .currency +
                                                                          (totalValue -
                                                                              coupenData?.price)
                                                                    : _userData.user.business_id
                                                                          .currency +
                                                                      parseInt(
                                                                          totalValueDrawer
                                                                      ).toFixed(2)}
                                                            </TotalPrice>
                                                        </TotalPriceBody>
                                                    </PlatformDiv>
                                                    <PlatformDiv>
                                                        {_userData.user_login !== 'customer' && (
                                                            <TotalPriceBody>
                                                                <PlatForm>
                                                                    {t('Created at')}
                                                                </PlatForm>
                                                                <CreatedFormText>
                                                                    {createAt}
                                                                </CreatedFormText>
                                                            </TotalPriceBody>
                                                        )}
                                                        <TotalPriceBody>
                                                            <PlatForm>{t('Updated at')}</PlatForm>
                                                            <CreatedFormText>
                                                                {updateAt}
                                                            </CreatedFormText>
                                                        </TotalPriceBody>
                                                    </PlatformDiv>

                                                    <FormFooter>
                                                        <FormButtons>
                                                            {payment == null &&
                                                            _userData.user_login !== 'customer' &&
                                                            paymentButtons === true ? (
                                                                <Button
                                                                    bgtype={'secondary'}
                                                                    ifClicked={() => {
                                                                        setChargeModel(true);
                                                                    }}
                                                                    label={t('Charge')}></Button>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                            <Button
                                                                bgtype={'secondary'}
                                                                ifClicked={() =>
                                                                    onSubmit(appointmentAssets)
                                                                }
                                                                label={t('Save')}></Button>
                                                        </FormButtons>
                                                    </FormFooter>
                                                </FormDetails>
                                            </FormContent>
                                        </DrawerBody>
                                    </AppointmentContent>
                                )}

                            {reportModel && (
                                <ReportModel
                                    onClose={() => setReportModel(false)}
                                    onSave={() => {
                                        getAppointmentById(appointment?._id);
                                        setReportModel(false);
                                    }}
                                    appointmentData={appointment}
                                    dashboard={true}
                                />
                            )}

                            {redeemModel && (
                                <RedeemModel
                                    onClose={() => setRedeemModel(false)}
                                    data={coupenModelData}
                                    appointmentData={appointment}
                                    onSave={e => {
                                        setCoupenData(e);
                                        setRedeemModel(false);
                                    }}
                                />
                            )}

                            {appointmentInvoiceModel && (
                                <AppointmentInvoice
                                    onClose={() => {
                                        setAppointmentInvoiceModel(false);
                                        setActive(false);
                                    }}
                                    appointmentData={{
                                        appointment: {
                                            ...appointmentData,
                                        },
                                    }}
                                />
                            )}

                            {chargeModel === true && (
                                <CompletePayment
                                    newAppointmentModel={false}
                                    stripeId={selectedCustomer?.stripe_id}
                                    onClose={() => setChargeModel(false)}
                                    onSubmit={() => {
                                        if (_userData?.user?.business_id?.invoice == true) {
                                            setAppointmentInvoiceModel(true);
                                        } else {
                                            setActive(false);
                                        }
                                    }}
                                    selectedService={selectedService}
                                    tipValue={appointment?.total_value}
                                    platForm={appointment?.platform}
                                    selectedProfessional={selectedServiceProvider}
                                    customerDetails={selectedCustomer}
                                    calendarDate={appointment?.booked_from_iso}
                                    appointment={appointment}
                                />
                            )}

                            {customerView && (
                                <SelectClient
                                    onClose={() => {
                                        setCustomerView(false);
                                    }}
                                    onSelect={(customer: ICustomer) => {
                                        setSelectedCustomer(customer);
                                        updateEvent({ customer });
                                    }}
                                />
                            )}

                            {serviceProviderView && (
                                <SelectServiceProvider
                                    onClose={() => {
                                        setServiceProviderView(false);
                                    }}
                                    onSelect={(serviceProvider: IServiceProvider) => {
                                        setSelectedServiceProvider(serviceProvider);
                                        setProviderId(serviceProvider._id);
                                    }}
                                />
                            )}

                            {serviceView && (
                                <SelectService
                                    id={providerId}
                                    onClose={() => {
                                        setServiceView(false);
                                    }}
                                    selectedService={selectedService}
                                    onSelect={(service: any) => {
                                        const data = {
                                            price: service.price,
                                            _id: service.service_id,
                                            my_price: service.my_price,
                                            my_duration: service.my_duration,
                                            duration: service.duration,
                                            name: service.name,
                                            color: service.color,
                                            available_for_boooking: service.available_for_boooking,
                                            is_video: service?.is_video,
                                            assets: service?.assets,
                                        };
                                        setAppointmentAssets(service?.assets);
                                        setSelectedService(data);
                                        setServiceDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationMinutes(service.my_duration % 60);
                                        setServiceDurationMinutes(service.my_duration % 60);
                                        setIs_video(service?.is_video);
                                        updateEvent({ service });
                                    }}
                                />
                            )}

                            {viewAssets && (
                                <ServiceAssets
                                    onClose={() => {
                                        setViewAssets(false);
                                    }}
                                    selectedService={appointmentAssets}
                                    setUpdatedAssets={async (e: any) => {
                                        await onSubmit(e, false);
                                        setViewAssets(false);
                                    }}
                                />
                            )}

                            {showRecurringModal && appointment?.recurring && (
                                <DeleteRecurringModal
                                    deleteOne={appointment !== undefined && appointment._id}
                                    deleteAll={
                                        recurringId !== undefined && recurringId.recurring_id
                                    }
                                    futureDelete={appointment !== undefined && appointment._id}
                                    onClose={() => setShowRecurringModal(false)}
                                    getAppointments={getAppointments}
                                    onDelete={() => {
                                        setShowRecurringModal(false);
                                    }}
                                />
                            )}

                            {showAppointmentModal && appointment && (
                                <DeleteModel
                                    id={id}
                                    onDelete={() => {
                                        setShowAppointmentModal(false);
                                    }}
                                    onClose={() => {
                                        setShowAppointmentModal(false);
                                    }}
                                    name={t('Appointment')}
                                    content={t('Are you sure you want to delete this appointment?')}
                                    loading={loading}
                                    onClick={() => singleAppointmentDelete()}
                                />
                            )}

                            {showConflictModal && (
                                <ConflictModal
                                    dates={conflictDates}
                                    onContinue={() => {
                                        setShowConflictModal(false);
                                        setOverbooking(true);
                                    }}
                                    onClose={() => setShowConflictModal(false)}
                                />
                            )}
                        </Drawer>
                    </Col>
                </Row>

                {(customerEditModel === true || customerModel) && (
                    <CustomerModal
                        onSave={(data: any) => {
                            setSelectedCustomer(data);
                            setCustomerModel(false);
                            setCustomerEditModel(false);
                        }}
                        onEdit={selectedCustomer}
                        editModel={customerEditModel}
                        title={customerEditModel === true ? 'Edit Customer' : 'Add New Customer'}
                        onClose={() => {
                            setCustomerModel(false);
                            setCustomerEditModel(false);
                        }}
                    />
                )}
            </Container>
        </>
    );
};

const AppointmentContent = styled(Col)<any>`
    height: 100%;
    display: ${({ display }) => (display ? 'block' : 'flex')};
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
    justify-content: space-between;
`;

const CloseButton = styled.div`
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.75rem;
    line-height: 2.5rem;
`;

const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem /* 8px */;
    padding: 0 1.75rem;
    overflow: auto;
`;

const AppointmentCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    background: ${palette.white};
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    justify-content: space-between;
`;

const ServiceProviderContent = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    background: ${palette.white};
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-bottom: 0.5rem;
`;

const FormContent = styled.div`
    flex: 1 1 0%;
`;

const FormDetails = styled.div`
    padding-top: 2rem;
    height: calc(100% - 84px);
`;

const DatesBody = styled.div`
    justify-content: space-between !important;
    display: -webkit-box !important;
    margin-bottom: 0.5rem /* 24px */;
`;

const FormDate = styled.div`
    font-weight: 500;
    font-size: 1.35rem;
    display: block;
    justify-content: center;
    align-items: center;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: relative;
        top: -1.8rem;
        width: 8rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;

const CustomButton = styled.div``;

const RepeateButton = styled.button`
    padding: 0.92rem 1rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.57rem;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    color: rgba(255, 255, 255);
    border-width: 1px;
    border-color: rgba(73, 196, 150);
    background: ${palette.secondary};
    border: none;
    cursor: pointer;
`;

const RepeateIcon = styled.i`
    margin-left: 0.5rem /* 8px */;
`;

const SelectServiceContent = styled.div`
    border-radius: 0.5rem /* 8px */;
    padding: 12px 12px 12px 20px;
    margin-bottom: 0.5rem;
    width: 100%;
    display: flex;
    position: relative;
    border: 1px solid rgba(223, 226, 230);
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    cursor: pointer;
`;

const ServiceHeader = styled.div`
    color: rgba(125, 128, 131);
`;

const InputGroup = styled.div`
    margin-bottom: 1rem;
`;

const InputContent = styled(Row)`
    margin-bottom: 1rem;
`;

const ServiceBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left-width: 4px;
    border-radius: 8px 0 0 8px;
`;

const ServiceDetails = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NameBody = styled.div`
    display: flex;
    flex-direction: column;
`;

const ServiceName = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
`;

const ServiceDuration = styled.div`
    font-size: 0.925rem;
    color: rgba(157, 160, 164);
`;

const PriceBody = styled.div`
    display: flex;
    align-items: center;
`;

const ServicePrice = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 18px;
`;

const SelectServiceBody = styled.div`
    border-radius: 0.5rem /* 8px */;
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
    margin-bottom: 2rem /* 32px */;
    align-items: center;
    display: flex;
    border: 1px solid rgba(223, 226, 230);
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    cursor: pointer;
    justify-content: space-between;
`;

const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;

const Label = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
`;

const Icons = styled.i`
    font-size: 1.125rem;
    line-height: 1.5rem !important;
`;

const NameCircle = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;

const PlatformDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TotalPriceBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem /* 16px */;
`;

const PlatForm = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    text-transform: capitalize;
    color: rgba(188, 192, 197);
    text-align: left;
`;

const PlatFormText = styled.div`
    font-size: 1.3rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 600;
    text-align: left;
`;
const CreatedFormText = styled.div`
    font-size: 0.8rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 600;
    text-align: left;
`;

const PriceHeader = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    text-transform: capitalize;
    color: rgba(188, 192, 197);
    text-align: right;
`;

const TotalPrice = styled.div`
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 600;
    text-align: right;
`;
const FormFooter = styled.div`
    padding: 0.5rem 0rem;
    background: ${palette.white};
`;

const FormButtons = styled.div`
    display: flex;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem /* 24px */;
    justify-content: space-between;
`;

const CustomerContent = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
    flex-direction: column;
`;

const Name = styled.div`
    font-weight: 500;
`;

const Email = styled.div`
    color: ${palette.grey.grey_20};
    font-size: 0.9rem;
`;

const ToggleButton = styled.div`
    display: flex;
`;

const ToggleDurationButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CustomSpan = styled.span`
    font-size: 0.8rem;
`;

const StatusButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.57rem;
    font-size: 0.92rem;
    padding: 0.71rem 1.57rem;
    border: none;
    cursor: pointer;
`;

const DownIcon = styled.i`
    margin-left: 0.5rem /* 8px */;
    font-size: 1.125rem;
    line-height: 1.75rem;
`;

const Status = styled.div`
    border-radius: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
`;

const CalenderDate = styled.div`
    cursor: pointer;
`;

const SelectCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    background: ${palette.white};
    padding: 0.2rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    justify-content: space-between;
`;

const IconsDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: -0.7rem;
`;

const WaitingListText = styled.div`
    margin: 0 1.7rem;
    padding: 0.3rem;
`;

export default EditAppointmentDrawer;
